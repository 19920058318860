@tailwind base;
@tailwind components;
@tailwind utilities;

/* solitreo-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Solitreo';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/solitreo-v2-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* mulish-300 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/mulish-v12-latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* mulish-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/mulish-v12-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* mulish-500 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/mulish-v12-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* mulish-600 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/mulish-v12-latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* mulish-700 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/mulish-v12-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* mulish-800 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/mulish-v12-latin-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* m-plus-1-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'M PLUS 1';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/m-plus-1-v6-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* m-plus-1-500 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'M PLUS 1';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/m-plus-1-v6-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* m-plus-1-600 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'M PLUS 1';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/m-plus-1-v6-latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* m-plus-1-800 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'M PLUS 1';
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/m-plus-1-v6-latin-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

body {
    margin: 0;
    font-family: 'Mulish', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@layer utilities {
    .focus-ring {
        @apply outline-none ring-2 ring-offset-2 ring-indigo-500 rounded-md;
    }
}

@layer utilities {
    .focus-border {
        @apply outline-none ring-1 ring-indigo-500 border-indigo-500;
    }
}

@layer utilities {
    .max-h-modal {
        max-height: 85vh;
    }

    .max-h-70-vh {
        max-height: 70vh;
    }
}

@layer base {
    :root {
        /*fonts*/
        --heading-font: 'M PLUS 1', sans-serif;
        --quotation-font: 'Solitreo', cursive, sans-serif;

        /*buttons*/
        --color-cta-button-top: #4f46e5;
        --color-cta-button-bottom: #3730a3;
        --color-cta-button-hover: #4338ca;
        --color-cta-button-border: #d1d5db;
        --color-grey-button-top: #ffffff;
        --color-grey-button-bottom: #f3f4f6;
        --color-grey-button-hover: #f3f4f6;
        --color-grey-button-border: #d1d5db;

        /*modal icons*/
        --color-modal-icon: #059669;
        --color-modal-icon-bg: #d1fae5;

        /*empty state icons*/
        --color-empty-state-icon: #93c5fd;

        /*page*/
        --color-page-background: #f0f1f1;

        /*heading text colour*/
        --color-heading-text: #374151;

        /*menu themes*/
        --color-styre-900: #064e3b;
        --color-styre-700: #047857;
        --color-styre-500: #10b981;
        --color-styre-100: #d1fae5;

        --color-admin-900: #78350f;
        --color-admin-700: #b45309;
        --color-admin-600: #d97706;
        --color-admin-100: #fef3c7;

        --color-forvalter-900: #164e63;
        --color-forvalter-700: #0e7490;
        --color-forvalter-500: #06b6d4;
        --color-forvalter-200: #a5f3fc;
    }

    .theme-styreportal {
        --color-primary-110: var(--color-styre-900);
        --color-primary-100: var(--color-styre-700);
        --color-primary-90: var(--color-styre-500);
        --color-primary-10: var(--color-styre-100);
    }

    .theme-admin {
        --color-primary-110: var(--color-admin-900);
        --color-primary-100: var(--color-admin-700);
        --color-primary-90: var(--color-admin-600);
        --color-primary-10: var(--color-admin-100);
    }

    .theme-forvaltning {
        --color-primary-110: var(--color-forvalter-900);
        --color-primary-100: var(--color-forvalter-700);
        --color-primary-90: var(--color-forvalter-500);
        --color-primary-10: var(--color-forvalter-200);
    }

    .theme-transition {
        transition: colors 1s;
    }

    .scaleup {
        animation: scaleup 1000ms;
    }

    .scaledown {
        animation: scaledown 1000ms;
    }

    .animate-color {
        transition: color 1s;
    }

    .fade-and-rotate {
        animation: fadeout 500ms, fadein 500ms 500ms, rotate180 1000ms;
    }

    .fade-and-rotate-right {
        animation: fadeout 500ms, fadein 500ms 500ms, rotateminus180 1000ms;
    }

    .rotate-minus-180 {
        animation: rotateminus180 1000ms;
    }

    .rotate-180 {
        animation: rotate180 1000ms;
    }

    .underline-post-animation::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        transform-origin: bottom right;
    }

    .underline-in-animation::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        transform-origin: bottom right;
        animation: scalefromnothing 1000ms;
    }

    .underline-out-animation::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        transform-origin: bottom left;
        animation: scaletonothing 1000ms;
    }

    @keyframes scalefromnothing {
        from {
            transform: scaleX(0);
        }
        to {
            transform: scaleX(1);
        }
    }
    @keyframes scaletonothing {
        from {
            transform: scaleX(1);
        }
        to {
            transform: scaleX(0);
        }
    }

    @keyframes scaleup {
        from {
            transform: scale(1);
        }
        to {
            transform: scale(1.3);
        }
    }
    @keyframes scaledown {
        from {
            transform: scale(1.3);
        }
        to {
            transform: scale(1);
        }
    }

    @keyframes fadeout {
        100% {
            opacity: 0.1;
        }
        0% {
            opacity: 1;
        }
    }

    @keyframes fadein {
        100% {
            opacity: 1;
        }
        0% {
            opacity: 0.1;
        }
    }

    @keyframes rotate180 {
        100% {
            transform: rotate(180deg);
        }
        0% {
            transform: rotate(0deg);
        }
    }
    @keyframes rotateminus180 {
        100% {
            transform: rotate(-180deg);
        }
        0% {
            transform: rotate(0deg);
        }
    }
}

input[type='search']::-webkit-search-cancel-button {
    cursor: pointer;
}

input[type='range'].range-month {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    opacity: 1;
    outline: none;
    margin: 0 auto;
}

.range-month {
    appearance: none;
    -webkit-appearance: none;
    margin: 20px 0;
    width: 100%;
    border-radius: 8px;

    outline: none;

}

.range-month:focus {
    outline: none;
}

.range-month::-webkit-slider-thumb {
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background-color: #4F46E5;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
}

#sentry-feedback {
    --right: auto;
    --bottom: auto;
}
